.skillDes{
    margin-top:40px ;
    margin-bottom: 30px;
}


#stats img{
    border:2px solid transparent; 
    border-radius:20px ;
    width: 40%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
   
}

.git1{
 margin-bottom: 20px;
}

@media screen and (max-width:1024px) {
    #stats img{
        width:100%;
    }

}

@media screen and (max-width:600px) {
    #stats img{
        width:80%;
    }
}