.container.contact__container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12rem;
}

.contact__options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent ;
    transition:  var(--transition);
}

.contact__option:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;

}
.contact__option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* form s888888888888888888888888888 */

form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    /* border: 2px solid var(--color-primary-variant); */
    border: 2px solid #8b068d;
    resize: none;
    color: var(--color-white);
}

/* Media quries (Medium devices)*/
@media screen and (max-width:1024px) {
    .container.contact__container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }

}

/* Media quries (small devices)*/

@media screen and (max-width:600px) {
    .container.contact__container{
        
        width: var(--container-width-sm);
    }
}