@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap');

*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}
/* --color-bg: #1b1b31 
 --color-primary:#60b7f4;
  --color-primary-variant:rgba(77,181,255,0.4);
  rgb(208, 179, 184)
  rgb(26, 69, 69)
  #5f5fa5
*/
/* #5f5fa5 */
:root{
 --color-bg: #0e0e11;
 /* --color-bg: #3B2D3F; */
 /* --color-bg: #2e2e3a; */
 /* --color-bg-variant :rgba(70, 155, 216, 0.4); */
 /* --color-bg-variant: #4a1f5c; */
 --color-bg-variant: #31173c;
 --color-primary: black ;
 --color-primary-variant:rgba(129, 187, 228, 0.4);
 --color-white:#fff;
 --color-light:rgba(255,255,255,0.6);

 --container-width-lg:75%;
 --container-width-md:86%;
 --container-width-sm:90%;

 --transition: all 400ms ease;
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

body{
   font-family: 'Poppins', sans-serif;
   background: var(--color-bg);
   color: var(--color-white);
   line-height: 1.7;
   /* background-image:url(../src/assets/bg-texture.png); */
}

/* ===========general Styles */

.container{
    width: var(--container-width-lg);
    margin: 0 auto;

}

h1,h2,h3,h4,h5{
    font-weight: 500;
}

h1{
    font-size: 2.5rem;
}

section{
    margin-top: 8rem;
    
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}
/* var(--color-primary) */
section > h2 {
    color: #8b068d;;
    margin-bottom: 3rem;
}

.text-light{
    color: var(--color-light);
}

a{
    color:  #8b068d;
    /* color:black; */
    transition: var(--transition);
}

a:hover{
    color: var(--color-white);
}

.btn{
    width: max-content;
    display: inline-block;
    /* color:#5f5fa5; */
    /* color: #8b068d; */
    color: #fff;
    padding: 0.75rem 1.2rem ;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid  #8b068d;
    transition: var(--transition);
}

.btn:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    /* background: #5f5fa5; */
    background:  #8b068d;
    /* color: var(--color-bg); */
    color: #fff;
}

img{
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ++++++++++++ media queries */
@media screen and (max-width: 1024px)  {
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
}
/*  small devices  */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-top: 2rem;
    }
}